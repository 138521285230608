<template>
  <div>
    <div class="flex items-center w-full p-2 gap-1 bg-theme-primary">
      <p class="leading-[28px] text-[20px] font-bold">{{ $t("spots.filter") }}</p>
      <button
          v-if="!isEmpty()"
          class="flex items-center gap-0.5 transition-opacity duration-100 hover:text-theme-secondary text-xs"
          @click="emptyAllFilters"
      >
        <img class="w-[10px] h-[10px]" src="~assets/icon/close.svg"
             :alt="t('spots.filter_delete_all_filter_button_title')"/>{{
          $t("spots.filter_delete_all_filter_button_title")
        }}
      </button>
      <button class="ml-auto" @click="$emit('close')">
        <img src="~/assets/icon/close.svg" :alt="t('accessibility.close')"/>
      </button>
    </div>
    <ErrorMessage v-if="errorMessage" :message="errorMessage"
                  :small="true" class="mb-2"/>
    <div v-else class="flex flex-col bg-theme-primary text-theme-font gap-2 p-2">
      <!-- Type -->
      <div>
        <div class="flex items-end">
          <p class="grow uppercase">{{ $t("spots.filter_title_type") }}</p>
          <FilterBarDeleteButton
              :visible="selectedType != 0"
              @click="emptySelectedType()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0"/>
        <div class="flex border-[1px] border-black rounded-lg divide-black">
          <input
              class="hidden"
              type="radio"
              id="type-filter-none"
              name="type-filter"
              v-model="selectedType"
              :value="0"
              @change="loadNumberOfResults"
          />
          <input
              class="hidden peer/fame"
              type="radio"
              id="type-filter-fame"
              name="type-filter"
              v-model="selectedType"
              :value="1"
              @change="loadNumberOfResults"
          />
          <label
              class="cursor-pointer rounded-l-[6px] grow text-center p-0.5 peer-checked/fame:bg-black peer-checked/fame:text-white"
              for="type-filter-fame"
          >{{ $t("spots.filter_text_fame") }}</label
          >
          <input
              class="hidden peer/shame"
              type="radio"
              id="type-filter-shame"
              name="type-filter"
              v-model="selectedType"
              :value="2"
              @change="loadNumberOfResults"
          />
          <label
              class="cursor-pointer border-l-black border-l-[1px] rounded-r-[6px] border-black grow text-center p-0.5 peer-checked/shame:bg-black peer-checked/shame:text-white"
              for="type-filter-shame"
          >{{ $t("spots.filter_text_shame") }}</label
          >
        </div>
      </div>
      <!-- Fixed -->
      <div v-if="selectedType != 1">
        <div class="flex items-end">
          <p class="grow uppercase">{{ $t("spots.filter_text_fixed") }}</p>
          <FilterBarDeleteButton
              :visible="fixed != undefined"
              @click="emptyFixed()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0"/>
        <div class="flex border-[1px] border-black rounded-lg divide-black">
          <input
              class="hidden"
              type="radio"
              id="fixed-filter-undefined"
              name="fixed-filter"
              v-model="fixed"
              :value="undefined"
              @change="loadNumberOfResults"
          />
          <input
              class="hidden peer/true"
              type="radio"
              id="fixed-filter-true"
              name="fixed-filter"
              v-model="fixed"
              :value="true"
              @change="loadNumberOfResults"
          />
          <label
              class="cursor-pointer rounded-l-[6px] grow text-center p-0.5 peer-checked/true:bg-black peer-checked/true:text-white"
              for="fixed-filter-true"
          >{{ $t("spots.filter_text_fixed_value") }}</label
          >
          <input
              class="hidden peer/false"
              type="radio"
              id="fixed-filter-false"
              name="fixed-filter"
              v-model="fixed"
              :value="false"
              @change="loadNumberOfResults"
          />
          <label
              class="cursor-pointer border-l-black border-l-[1px] rounded-r-[6px] border-black grow text-center p-0.5 peer-checked/false:bg-black peer-checked/false:text-white"
              for="fixed-filter-false"
          >{{ $t("spots.filter_text_not_fixed_value") }}</label
          >
        </div>
      </div>
      <!-- Category -->
      <div>
        <div v-if="categories.length" class="flex gap-0.5">
          <p class="grow uppercase line">{{ $t("spots.filter_title_category") }}</p>
          <FilterBarDeleteButton
              :visible="selectedCategorieIds.length > 0"
              @click="emptySelectedCategorieIds()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0"/>
        <div class="flex flex-wrap gap-0.5">
          <div v-for="category in categories" :key="category.id">
            <input
                type="checkbox"
                class="peer appearance-none"
                :id="category.id.toString()"
                :value="category.id"
                v-model="selectedCategorieIds"
                @change="loadNumberOfResults"
            />
            <label
                :for="category.id.toString()"
                class="peer-checked:bg-black peer-checked:text-white transition-all duration-75 cursor-pointer px-1 py-[2px] uppercase text-black border-black border-solid border-[1px] rounded-[20px] text-[12px]"
            >{{ category.name }}</label
            >
          </div>
        </div>
      </div>
      <!-- Show results button -->
      <div class="flex items-end justify-center">
        <ButtonSecondary
            v-if="!loading"
            class="grow"
            @click="$emit('submit', getParams())"
        >{{ numberOfResults + " Spots" }}
        </ButtonSecondary
        >
        <Loading v-if="loading"/>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  regionId?: number;
}>();
defineEmits(["submit", "close"]);

const selectedCategorieIds = ref<Array<number>>([]);

const selectedType = ref<number>(0);
const fixed = ref<boolean>();

const loading = ref<boolean>();
const errorMessage = ref<string | undefined>(undefined);
const initialParams = ref({});
const {t} = useI18n();

const categories = await movRequest.get<Array<Category>>(
    "categories"
).catch(() => {
  errorMessage.value = "generic";
  return []
});

watch(() => props.regionId, () => {
  loadNumberOfResults();
});

const {data: numberOfResults, error} = movRequest.useFetch<number>("spotPreviewsFilterResults", initialParams);

function emptyAllFilters() {
  selectedCategorieIds.value = [];
  selectedType.value = 0;
  fixed.value = undefined;
  loadNumberOfResults();
}

function emptySelectedCategorieIds() {
  selectedCategorieIds.value = [];
  loadNumberOfResults();
}

function emptySelectedType() {
  selectedType.value = 0;
  loadNumberOfResults();
}

function emptyFixed() {
  fixed.value = undefined;
  loadNumberOfResults();
}

function isEmpty() {
  return (
      selectedType.value == 0 &&
      fixed.value == undefined &&
      selectedCategorieIds.value.length == 0
  );
}

function getParams(): any {
  var params: any = {};

  if (props.regionId != undefined && props.regionId != 0) {
    params["regionId"] = props.regionId;
  }

  if (selectedType.value == 1) {
    params["famed"] = true;
  } else if (selectedType.value == 2) {
    params["famed"] = false;
  }

  if (fixed.value != undefined && selectedType.value != 1) {
    params["fixed"] = fixed.value;
  }

  if (
      selectedCategorieIds.value.length > 0
  ) {
    params["categoryIds"] = selectedCategorieIds.value;
  }

  return params;
}

function loadNumberOfResults() {
  loading.value = true;
  const params = getParams();

  movRequest
      .get<number>("spotPreviewsFilterResults", params)
      .then(function (response) {
        numberOfResults.value = response;
        loading.value = false;
      });
}

const setFilters = (filters: any) => {
  if (!filters) {
    selectedType.value = 0;
    fixed.value = undefined;
    selectedCategorieIds.value = [];
  } else {
    selectedType.value = filters.famed ? 1 : filters.famed === false ? 2 : 0;
    fixed.value = filters.fixed;
    selectedCategorieIds.value = filters.categoryIds ? filters.categoryIds : [];
  }
  loadNumberOfResults();
};

defineExpose({
  setFilters
})

</script>
<style lang="postcss" scoped>
hr {
  @apply mb-1;
}

select {
  @apply w-full appearance-none border-solid border-[1px] border-black rounded-[7px] p-0.5;

  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='25' height='15' stroke='black' stroke-width='1' fill='none'><path d='M5 5 L10 10 15 5' /></svg>");
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: right center;
}
</style>
